import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket!: Socket;

  constructor() {}

  connect(): void {
    if (this.socket && this.socket.ioSocket.connected) {
      console.log('Socket already connected');
      return;
    }

    const url = environment.SOCKET_BASE_URL;

    const authToken = localStorage.getItem('accessToken');

    const config: SocketIoConfig = {
      url: url,
      options: {
        extraHeaders: authToken ? { Authorization: authToken } : {},
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 2000,
      },
    };

    this.socket = new Socket(config);

    this.socket.connect();

    this.socket.fromEvent('connect').subscribe(() => {
      console.log('Connected to Socket.IO server');
    });

    this.socket.fromEvent('disconnect').subscribe(() => {
      console.log('Disconnected from Socket.IO server');
    });

    this.socket.fromEvent('connect_error').subscribe((error) => {
      console.error('Socket connection error:', error);
    });

    this.socket.fromEvent('connect_timeout').subscribe(() => {
      console.warn('Socket connection timed out');
    });
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
      console.log('Socket disconnected');
    }
  }

  emit(event: string, data: any): void {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  listen<T>(event: string): Observable<T> {
    return this.socket ? this.socket.fromEvent<T>(event) : new Observable<T>();
  }

  removeListener(event: string): void {
    if (this.socket) {
      this.socket.removeListener(event);
    }
  }
}
