import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { first, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ILoginPayload,
  ILoginResponse,
  ILoginVerifyPayload,
  ILoginVerifyResponse,
  ISwitchPayload,
  UserInfo,
} from './model';
import { Router } from '@angular/router';
import { UserType } from '@shared/enums';
import { FeedbackService } from '@shared/services/feedback.service';
import { TranslocoService } from '@jsverse/transloco';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { AcademicYearsScopeService } from '@core/academic-years-scope.service';
import { FirebasePushNotificationService } from '@shared/services/firebase-push-notification.service';

import { CometChat } from '@cometchat/chat-sdk-javascript';
import { faWarning } from '@fortawesome/pro-light-svg-icons';
import { HesToasterService } from '@shared/services/hes-toaster.service';
import { SocketService } from '@shared/services/socket.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  faUserPlus = faUserPlus;
  AUTH_API_BASE_URL = environment.AUTH_API_BASE_URL + '/auth';
  BE_API_BASE_URL = environment.BE_API_BASE_URL + '/';

  private readonly socketService = inject(SocketService);
  private httpClient = inject(HttpClient);
  private feedbackService = inject(FeedbackService);
  private translocoService = inject(TranslocoService);
  private readonly toaster = inject(HesToasterService);
  private academicYearScopeService = inject(AcademicYearsScopeService);
  private firebasePushNotificationService = inject(
    FirebasePushNotificationService,
  );
  private router = inject(Router);

  isLoggedInAsOtherUser = signal<boolean>(
    !!localStorage.getItem('login_as_data'),
  );
  isUserLoggedIn = signal<boolean>(
    JSON.parse(localStorage.getItem('isUserLoggedIn') ?? 'false'),
  );

  private _user = signal<UserInfo | null>(
    JSON.parse(localStorage.getItem('user') ?? 'null'),
  );
  user = this._user.asReadonly();

  readonly isUserPersonnel = computed<boolean>(() => {
    return this.user()?.type === UserType.PERSONNEL;
  });

  readonly isUserStudent = computed<boolean>(() => {
    return this.user()?.type === UserType.STUDENT;
  });

  readonly isUserGuardian = computed<boolean>(() => {
    return this.user()?.type === UserType.GUARDIAN;
  });

  private readonly _redirectUri = signal<string | null>(null);
  redirectUri = this._redirectUri.asReadonly();

  login(payload: ILoginPayload) {
    return this.httpClient.post<ILoginResponse>(
      `${this.AUTH_API_BASE_URL}/login`,
      payload,
    );
  }

  studentLogin(payload: ILoginPayload) {
    return this.httpClient
      .post<ILoginVerifyResponse>(`${this.AUTH_API_BASE_URL}/login`, payload)
      .pipe(
        tap((res) => {
          if (res.success && res.data.userInfo) {
            this.setSession(res);
            this.redirect();
          }
        }),
      );
  }

  verifyOtp(payload: ILoginVerifyPayload, headers?: { [key: string]: string }) {
    return this.httpClient
      .post<ILoginVerifyResponse>(
        `${this.AUTH_API_BASE_URL}/login/verify`,
        payload,
        {
          headers,
        },
      )
      .pipe(
        tap((res) => {
          if (res.success) {
            if (res.data.profilesDetected) {
              this.redirect();
            } else if (
              res.data.userInfo.type === UserType.STUDENT &&
              !res.data.userInfo.isPasswordSetup
            ) {
              this._user.set(res.data.userInfo);
              this.onSetupAccount(res);
            } else {
              this.setSession(res);
              this.redirect();
            }
          }
        }),
      );
  }

  async displayLoginConfirmationDialog(
    userId: number,
    profileId: number,
    type: UserType,
  ) {
    return await this.feedbackService.openFeedbackModal(
      {
        type: 'warning',
        modalTitle: this.translocoService.translate(
          'global.login_as_user.title',
        ),
        modalMessage: this.translocoService.translate(
          'global.login_as_user.txt',
        ),
        primaryBtnStr: this.translocoService.translate('global.login.btn'),
        secondaryBtnStr: this.translocoService.translate('global.cancel.btn'),
        icon: faWarning,
      },
      () => {
        this.backupOriginalProfile();
        this.loginAsUser({
          userId,
          id: profileId,
          userType: type,
        })
          .pipe(first())
          .subscribe({
            error: (err) => {
              this.toaster.showBackendError(err);
              this.clearBackupProfile();
            },
          });
      },
    );
  }

  backupOriginalProfile() {
    const backupData = {
      user: localStorage.getItem('user'),
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
      chatAuthToken: localStorage.getItem('chatAuthToken'),
      path: this.router.url,
      selectedSchoolStructureItem: localStorage.getItem(
        'selectedSchoolStructureItem',
      ),
    };
    localStorage.setItem('login_as_data', JSON.stringify(backupData));
  }

  restoreOriginalProfile() {
    const backupData = JSON.parse(localStorage.getItem('login_as_data') ?? '');
    localStorage.setItem('user', backupData.user ?? '');
    localStorage.setItem('accessToken', backupData.accessToken ?? '');
    localStorage.setItem('refreshToken', backupData.refreshToken ?? '');
    localStorage.setItem('chatAuthToken', backupData.chatAuthToken ?? '');
    localStorage.setItem(
      'selectedSchoolStructureItem',
      backupData.selectedSchoolStructureItem ?? '',
    );
    localStorage.removeItem('login_as_data');
    this.isLoggedInAsOtherUser.set(false);
    this.router.navigate([backupData.path]).then(() => {
      window.location.reload();
    });
  }

  clearBackupProfile() {
    localStorage.removeItem('login_as_data');
    this.isLoggedInAsOtherUser.set(false);
  }

  public loginAsUser(payload: ISwitchPayload) {
    return this.httpClient
      .post<ILoginVerifyResponse>(`${this.AUTH_API_BASE_URL}/login-as-user`, {
        userId: this.user()?.id,
        ...payload,
      })
      .pipe(
        tap((resp) => {
          this.setSession(resp);
          this.isLoggedInAsOtherUser.set(true);
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        }),
      );
  }

  public switchProfile(payload: ISwitchPayload) {
    return this.httpClient
      .post<ILoginVerifyResponse>(`${this.AUTH_API_BASE_URL}/switch-profile`, {
        ...payload,
        userId: this.user()?.id,
      })
      .pipe(
        tap((resp) => {
          this.setSession(resp);
        }),
      );
  }

  refreshToken() {
    return this.httpClient.post<ILoginResponse>(
      `${this.AUTH_API_BASE_URL}/refresh`,
      {
        userId: this.user()?.id,
      },
    );
  }

  private redirect() {
    this.router.navigateByUrl(this.redirectUri() ?? 'home');
    this.updateRedirectUri(null);
  }

  logout(isReload = true) {
    this.router.navigate(['login']).then(() => {
      this.chatlogout();
      this.socketService.disconnect();
      this.isUserLoggedIn.set(false);
      this.academicYearScopeService.resetState();
      this._user.set(null);
      this.clearBackupProfile();
      localStorage.removeItem('isUserLoggedIn');
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('chatAuthToken');
      localStorage.removeItem('selectedSchoolStructureItem');
      if (isReload) {
        window.location.reload();
      }
    });
  }

  public setSession(res: ILoginVerifyResponse) {
    this.chatLogin(res);
    this.isUserLoggedIn.set(true);
    localStorage.setItem('isUserLoggedIn', 'true');
    this._user.set(res.data.userInfo);
    localStorage.setItem('user', JSON.stringify(res.data.userInfo));
    this.firebasePushNotificationService.initPush();
  }

  chatLogin(res: ILoginVerifyResponse) {
    CometChat.getLoggedinUser()
      .then((user) => {
        if (!user) {
          CometChat.login({
            authToken: res.data.token.chatAuthToken,
          })
            .then((user) => {
              console.log('Comet Chat Login Successful:', { user });
            })
            .catch((error) => {
              console.log('Comet Chat Login failed with exception:', { error });
            });
        } else {
        }
      })
      .catch(console.log);
  }

  chatlogout() {
    CometChat.logout();
  }

  updateRedirectUri(url: string | null) {
    this._redirectUri.set(url);
  }

  // student login setup methods
  async onSetupAccount(res: ILoginVerifyResponse) {
    await this.feedbackService.openFeedbackModal(
      {
        type: 'warning',
        modalTitle: this.translocoService.translate('login.setup.title'),
        modalMessage: this.translocoService.translate('login.setup.txt'),
        primaryBtnStr: this.translocoService.translate('login.setup.btn'),
        secondaryBtnStr: this.translocoService.translate('login.skip.btn'),
        icon: faUserPlus,
      },
      () => {
        this.router.navigate(['login', 'setup']);
      },
      () => {
        this.setSession(res);
        this.redirect();
      },
    );
  }
}
